/* choose help */
.section-title {
    margin-bottom: 20px;
    text-align: center;
}
.section-title .sub-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 2px;
    text-transform: capitalize;
    color: #333;
}
.section-title .title {
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    text-transform: capitalize;
    color: #002A0F;
}
.section-title p {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #333;
    margin-top: 10px;
}
.choose-help {
    padding: 80px 0;
}

.choose-help .counselling-therapy {
    background: #FDF8F4;
}

.choose-help .counselling-therapy .inner-content {
    padding: 60px 25px 60px 100px;
    height: 100%;
}

.choose-help .counselling-therapy .inner-content h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 10px;
}

.choose-help .counselling-therapy .inner-content p {
    color: #333;
}

.choose-help .counselling-therapy .inner-content ul {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 15px;
    margin: 15px 0;
    padding: 0;
}

.choose-help .counselling-therapy .inner-content ul li {
    width: 30%;
    flex-grow: 1;
    text-align: center;
}

.choose-help .counselling-therapy .inner-content ul li .icon {
    width: 80px;
    height: 80px;
    background: #FFECD8;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin: 0 auto 10px;
}


.choose-help .counselling-therapy .inner-content ul li .icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.choose-help .counselling-therapy .inner-content ul li p {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    line-height: 24px;
}

.doctor-card {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
}

.doctor-card .doctor-image {
    width: 100%;
    height: 300px;
}

.doctor-card .doctor-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.doctor-card .doctor-content {
    padding: 20px 15px;
}

.doctor-card .doctor-content .doctor-name {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
}

.doctor-card .doctor-content .doctor-name h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #002A0F;
}

.doctor-card .doctor-content .doctor-name i {
    color: #1ABC9C;
}

.doctor-card .doctor-content p.title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #767676;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 40px;
}

.doctor-card .doctor-content .feedback {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.doctor-card .doctor-content .feedback span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    margin: 0;
    padding: 0;
    line-height: 1;
}
.doctor-card .doctor-content .feedback span i {
    color: #E1E1E1;
    font-size: 14px;
    vertical-align: baseline;
}
.doctor-card .doctor-content .feedback span{
    color: #F1C40F;
}
.doctor-card .doctor-content .feedback p {
    font-weight: 500;
    font-size: 12px;
    color: #767676;
}
.doctor-card .doctor-content .doctor-info {
    margin: 10px 0;
}
.doctor-card .doctor-content .doctor-info ul{
    margin: 0;
    padding: 0;
}
.doctor-card .doctor-content .doctor-info ul li {
    padding-left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #767676;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.doctor-card .doctor-content .doctor-info ul li i {
    position: absolute;
    left: 0;
}
.doctor-card .doctor-content .theme-btn {
    padding: 8px 10px 8px 20px;
    display: inline-flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    background: transparent;
    border: 2px solid #002A0F;
    border-radius: 10px;
    color: #002A0F;
    margin-top: 10px;
    text-transform: capitalize;
    transition: all 0.3s ease-in;

}
.doctor-card .doctor-content .theme-btn i {
    background: #F5D823;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    color: #000 ;
    font-size: 14px; 
    line-height: 1 ;
    vertical-align: middle;
    transition: all 0.4s ease-in-out;
}
.doctor-card .doctor-content .theme-btn:hover {
    background-color: transparent;
    color: #002A0F;
}
.doctor-card .doctor-content .theme-btn:hover i {
    transform: translateX(6px);
}
.our-doctor .owl-stage{
    padding: 10px 0;
}
 /*Mobile Responsive */
 @media (max-width:1199px) {  
    .choose-help .counselling-therapy .inner-content {
        padding: 40px 25px 40px 40px;
    }
 }
 @media (max-width:991px) {
    .choose-help .counselling-therapy .inner-content ul li{
        width: 25%;
    }
 }
 @media (max-width:767px) {  
    .choose-help .counselling-therapy  {
        height: auto;
        margin-bottom: 30px;
    }
    .choose-help .counselling-therapy .inner-content ul{
        margin-bottom: 20px;
        gap: 10px;
    } 
    .choose-help .counselling-therapy .inner-content{
        text-align: center;
    }
    .doctor-card .doctor-content .doctor-name h4 {
        font-size: 16px;
        line-height: 1.5;
    }
    .doctor-card .doctor-content p.title{
        font-size: 12px;
        overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    }
    .doctor-card .doctor-content .theme-btn {
        padding: 6px 10px 6px 15px;
    }
    .choose-help .counselling-therapy .inner-content ul li .icon {
        width: 70px;
        height: 70px;
    }
    .doctor-card .doctor-content {
        padding: 15px 8px;
    }
 }
 @media (max-width:575px) {
    .choose-help {
        padding: 30px 0;
    }
    .choose-help .counselling-therapy .inner-content{
        padding: 20px 20px;
    }
    .doctor-card .doctor-content .doctor-info ul li:nth-child(1){
        display: none;
    }
    .doctor-card .doctor-content .doctor-info ul li:nth-child(2){
        display: none;
    }
    .doctor-card .doctor-content .feedback {
        flex-wrap: wrap;
        gap: 2px;
        margin: 5px 0;
    }
    .doctor-card .doctor-content .doctor-name {
        gap: 4px;
        align-items: center;
    }
    .doctor-card .doctor-content .doctor-name i{
        font-size: 14px;
        margin-top: 0;
    }
    .doctor-card .doctor-content .feedback span{
        display: flex;
        align-items: center;
    }
 }
 @media (min-width: 769px) and (max-width: 1281px) { 
    .doctor-card .doctor-content .doctor-name h4 {
        font-size: 16px;
    }
    .doctor-card .doctor-content p.title{
        font-size: 12px;
    }
 }