/* Testimonial */
.testimonial-section {
    padding: 80px 0;
}

.testimonial-section .testimonial-content h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    text-transform: capitalize;
    color: #002A0F;
    margin-bottom: 15px;
}

.testimonial-section .testimonial-content p {
    color: #333;
}

.testimonial-slider .testimonial-card {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 25px 15px;
    position: relative;
}

.testimonial-slider .testimonial-card .testimonial-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
    position: relative;
}

.testimonial-slider .testimonial-card .testimonial-header .user-image img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 100%;
}

.testimonial-slider .testimonial-card .testimonial-header .user-name h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}

.testimonial-slider .testimonial-card .testimonial-header .user-name h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #777777;
}
.testimonial-slider .testimonial-card p {
    font-weight: 400;
    color: #333;
}
.testimonial-slider .testimonial-card span {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 60px;
    line-height: 1;
    color: #FBE0C5;
    z-index: 0;
}
.owl-theme .owl-dots .owl-dot.active span{
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span {
    width: 40px;
    height: 10px;
    background-color: #002A0F;
}