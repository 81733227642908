/* blog */
.blog-section {
    padding: 100px 0;
  }
  
  .blog-slider .owl-stage {
    padding: 15px 0;
  }
  
  .blog-slider .item {
    padding: 0 8px;
  }
  
  .card-blog {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
  
  .card-blog .blog-image {
    width: 100%;
    height: 200px;
  }
  
  .card-blog .blog-image img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    object-position: top;
  }
  
  .card-blog .blog-content {
    position: relative;
    padding: 25px 15px;
  }
  
  .card-blog .blog-content h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 90px;
  }
  
  .card-blog .blog-content .blog-date {
    background: #002A0F;
    border-radius: 5px;
    padding: 6px 12px;
    display: inline-block;
    position: absolute;
    right: 15px;
    top: -15px;
    color: #ddd;
    font-size: 12px;
    font-weight: 600;
  }
  
  .card-blog .blog-content .theme-btn {
    margin-top: 15px !important;
    background: rgba(245, 216, 35, 0.2) !important;
    color: #002A0F !important;
    padding: 10px 25px !important;
    display: inline-flex !important; 
    font-size: 14px !important;
    line-height: 1.5 !important;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    border: 2px solid rgba(245, 216, 35, 0.2) !important;
    border-radius: 100px !important;
    text-transform: capitalize !important;
    transition: all 0.3s ease-in !important;
  }

  @media (max-width:767px) {
    .card-blog .blog-content h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      height: 85px;
    }
  }
  