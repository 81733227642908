/* our Courses */
.ourcourses-section {
  padding: 60px 0;
  background: #A1B8CD;
}

.ourcourses-section::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 0;
  background-image: url(/images/icon/flower-2.svg);
  background-position: center;
  background-size: contain;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  z-index: 0;
  -webkit-animation: moveUptop 1.5s infinite alternate;
  animation: moveUptop 1.5s infinite alternate;
}

.ourcourses-section .courses-head-img {
  align-items: center;
  margin-bottom: 30px;
}

.ourcourses-section .courses-head-img img {
  width: 80%;
  height: auto;
  display: block;
  margin: auto;
  -webkit-animation: moveRightLeft 1s infinite alternate;
  animation: moveRightLeft 1s infinite alternate;
}

.ourcourses-section .courses-slider .owl-stage {
  padding: 20px 0;
}

.courses-card {
  width: 100%;
  height: 100%;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #FAFAFD;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  overflow: hidden;
}

.courses-card .courses-image {
  width: 100%;
  height: 200px;
}

.courses-card .courses-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.courses-card .courses-content {
  padding: 15px;
}

.courses-card .courses-content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #333;
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 75px;
}

.courses-card .courses-content h4 {
  font-weight: 500;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  margin: 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 63px;
}

.courses-card .courses-content ul {
  margin: 0;
  padding: 0;
}

.courses-card .courses-content ul,
.courses-card .courses-content ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.courses-card .courses-content ul li {
  color: #767676;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  margin: 5px 0;
}

.courses-card .courses-content .theme-btn {
  background: transparent !important;
  border-radius: 10px !important;
  color: #002A0F !important;
  padding: 8px 10px 8px 20px !important;
  margin-top: 10px !important;
}
@-webkit-keyframes moveUptop {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes moveUptop {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@-webkit-keyframes moveRightLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(10px);
  }
}

@keyframes moveRightLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(10px);
  }
}
@media (min-width: 769px) and (max-width: 1281px) {
  .courses-card .courses-content h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 50px;
    font-size: 18px;
  }
}